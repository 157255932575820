.tippy-iOS {
  cursor: pointer !important; }

.tippy-notransition {
  transition: none !important; }

.tippy-popper {
  perspective: 700px;
  z-index: 9999;
  outline: 0;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  pointer-events: none;
  line-height: 1.4; }
  .tippy-popper[x-placement^='top'] .tippy-backdrop {
    border-radius: 40% 40% 0 0; }
  .tippy-popper[x-placement^='top'] .tippy-roundarrow {
    bottom: -8px;
    transform-origin: 50% 0%; }
    .tippy-popper[x-placement^='top'] .tippy-roundarrow svg {
      position: absolute;
      left: 0;
      transform: rotate(180deg); }
  .tippy-popper[x-placement^='top'] .tippy-arrow {
    border-top: 8px solid #333;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
    bottom: -7px;
    margin: 0 6px;
    transform-origin: 50% 0%; }
  .tippy-popper[x-placement^='top'] .tippy-backdrop {
    transform-origin: 0% 25%; }
    .tippy-popper[x-placement^='top'] .tippy-backdrop[data-state='visible'] {
      transform: scale(1) translate(-50%, -55%);
      opacity: 1; }
    .tippy-popper[x-placement^='top'] .tippy-backdrop[data-state='hidden'] {
      transform: scale(0.2) translate(-50%, -45%);
      opacity: 0; }
  .tippy-popper[x-placement^='top'] [data-animation='shift-toward'][data-state='visible'] {
    opacity: 1;
    transform: translateY(-10px); }
  .tippy-popper[x-placement^='top'] [data-animation='shift-toward'][data-state='hidden'] {
    opacity: 0;
    transform: translateY(-20px); }
  .tippy-popper[x-placement^='top'] [data-animation='perspective'] {
    transform-origin: bottom; }
    .tippy-popper[x-placement^='top'] [data-animation='perspective'][data-state='visible'] {
      opacity: 1;
      transform: translateY(-10px) rotateX(0); }
    .tippy-popper[x-placement^='top'] [data-animation='perspective'][data-state='hidden'] {
      opacity: 0;
      transform: translateY(0) rotateX(60deg); }
  .tippy-popper[x-placement^='top'] [data-animation='fade'][data-state='visible'] {
    opacity: 1;
    transform: translateY(-10px); }
  .tippy-popper[x-placement^='top'] [data-animation='fade'][data-state='hidden'] {
    opacity: 0;
    transform: translateY(-10px); }
  .tippy-popper[x-placement^='top'] [data-animation='shift-away'][data-state='visible'] {
    opacity: 1;
    transform: translateY(-10px); }
  .tippy-popper[x-placement^='top'] [data-animation='shift-away'][data-state='hidden'] {
    opacity: 0;
    transform: translateY(0); }
  .tippy-popper[x-placement^='top'] [data-animation='scale'][data-state='visible'] {
    opacity: 1;
    transform: translateY(-10px) scale(1); }
  .tippy-popper[x-placement^='top'] [data-animation='scale'][data-state='hidden'] {
    opacity: 0;
    transform: translateY(0) scale(0.5); }
  .tippy-popper[x-placement^='bottom'] .tippy-backdrop {
    border-radius: 0 0 30% 30%; }
  .tippy-popper[x-placement^='bottom'] .tippy-roundarrow {
    top: -8px;
    transform-origin: 50% 100%; }
    .tippy-popper[x-placement^='bottom'] .tippy-roundarrow svg {
      position: absolute;
      left: 0;
      transform: rotate(0); }
  .tippy-popper[x-placement^='bottom'] .tippy-arrow {
    border-bottom: 8px solid #333;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
    top: -7px;
    margin: 0 6px;
    transform-origin: 50% 100%; }
  .tippy-popper[x-placement^='bottom'] .tippy-backdrop {
    transform-origin: 0% -50%; }
    .tippy-popper[x-placement^='bottom'] .tippy-backdrop[data-state='visible'] {
      transform: scale(1) translate(-50%, -45%);
      opacity: 1; }
    .tippy-popper[x-placement^='bottom'] .tippy-backdrop[data-state='hidden'] {
      transform: scale(0.2) translate(-50%, 0);
      opacity: 0; }
  .tippy-popper[x-placement^='bottom'] [data-animation='shift-toward'][data-state='visible'] {
    opacity: 1;
    transform: translateY(10px); }
  .tippy-popper[x-placement^='bottom'] [data-animation='shift-toward'][data-state='hidden'] {
    opacity: 0;
    transform: translateY(20px); }
  .tippy-popper[x-placement^='bottom'] [data-animation='perspective'] {
    transform-origin: top; }
    .tippy-popper[x-placement^='bottom'] [data-animation='perspective'][data-state='visible'] {
      opacity: 1;
      transform: translateY(10px) rotateX(0); }
    .tippy-popper[x-placement^='bottom'] [data-animation='perspective'][data-state='hidden'] {
      opacity: 0;
      transform: translateY(0) rotateX(-60deg); }
  .tippy-popper[x-placement^='bottom'] [data-animation='fade'][data-state='visible'] {
    opacity: 1;
    transform: translateY(10px); }
  .tippy-popper[x-placement^='bottom'] [data-animation='fade'][data-state='hidden'] {
    opacity: 0;
    transform: translateY(10px); }
  .tippy-popper[x-placement^='bottom'] [data-animation='shift-away'][data-state='visible'] {
    opacity: 1;
    transform: translateY(10px); }
  .tippy-popper[x-placement^='bottom'] [data-animation='shift-away'][data-state='hidden'] {
    opacity: 0;
    transform: translateY(0); }
  .tippy-popper[x-placement^='bottom'] [data-animation='scale'][data-state='visible'] {
    opacity: 1;
    transform: translateY(10px) scale(1); }
  .tippy-popper[x-placement^='bottom'] [data-animation='scale'][data-state='hidden'] {
    opacity: 0;
    transform: translateY(0) scale(0.5); }
  .tippy-popper[x-placement^='left'] .tippy-backdrop {
    border-radius: 50% 0 0 50%; }
  .tippy-popper[x-placement^='left'] .tippy-roundarrow {
    right: -16px;
    transform-origin: 33.33333333% 50%; }
    .tippy-popper[x-placement^='left'] .tippy-roundarrow svg {
      position: absolute;
      left: 0;
      transform: rotate(90deg); }
  .tippy-popper[x-placement^='left'] .tippy-arrow {
    border-left: 8px solid #333;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    right: -7px;
    margin: 3px 0;
    transform-origin: 0% 50%; }
  .tippy-popper[x-placement^='left'] .tippy-backdrop {
    transform-origin: 50% 0%; }
    .tippy-popper[x-placement^='left'] .tippy-backdrop[data-state='visible'] {
      transform: scale(1) translate(-50%, -50%);
      opacity: 1; }
    .tippy-popper[x-placement^='left'] .tippy-backdrop[data-state='hidden'] {
      transform: scale(0.2) translate(-75%, -50%);
      opacity: 0; }
  .tippy-popper[x-placement^='left'] [data-animation='shift-toward'][data-state='visible'] {
    opacity: 1;
    transform: translateX(-10px); }
  .tippy-popper[x-placement^='left'] [data-animation='shift-toward'][data-state='hidden'] {
    opacity: 0;
    transform: translateX(-20px); }
  .tippy-popper[x-placement^='left'] [data-animation='perspective'] {
    transform-origin: right; }
    .tippy-popper[x-placement^='left'] [data-animation='perspective'][data-state='visible'] {
      opacity: 1;
      transform: translateX(-10px) rotateY(0); }
    .tippy-popper[x-placement^='left'] [data-animation='perspective'][data-state='hidden'] {
      opacity: 0;
      transform: translateX(0) rotateY(-60deg); }
  .tippy-popper[x-placement^='left'] [data-animation='fade'][data-state='visible'] {
    opacity: 1;
    transform: translateX(-10px); }
  .tippy-popper[x-placement^='left'] [data-animation='fade'][data-state='hidden'] {
    opacity: 0;
    transform: translateX(-10px); }
  .tippy-popper[x-placement^='left'] [data-animation='shift-away'][data-state='visible'] {
    opacity: 1;
    transform: translateX(-10px); }
  .tippy-popper[x-placement^='left'] [data-animation='shift-away'][data-state='hidden'] {
    opacity: 0;
    transform: translateX(0); }
  .tippy-popper[x-placement^='left'] [data-animation='scale'][data-state='visible'] {
    opacity: 1;
    transform: translateX(-10px) scale(1); }
  .tippy-popper[x-placement^='left'] [data-animation='scale'][data-state='hidden'] {
    opacity: 0;
    transform: translateX(0) scale(0.5); }
  .tippy-popper[x-placement^='right'] .tippy-backdrop {
    border-radius: 0 50% 50% 0; }
  .tippy-popper[x-placement^='right'] .tippy-roundarrow {
    left: -16px;
    transform-origin: 66.66666666% 50%; }
    .tippy-popper[x-placement^='right'] .tippy-roundarrow svg {
      position: absolute;
      left: 0;
      transform: rotate(-90deg); }
  .tippy-popper[x-placement^='right'] .tippy-arrow {
    border-right: 8px solid #333;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    left: -7px;
    margin: 3px 0;
    transform-origin: 100% 50%; }
  .tippy-popper[x-placement^='right'] .tippy-backdrop {
    transform-origin: -50% 0%; }
    .tippy-popper[x-placement^='right'] .tippy-backdrop[data-state='visible'] {
      transform: scale(1) translate(-50%, -50%);
      opacity: 1; }
    .tippy-popper[x-placement^='right'] .tippy-backdrop[data-state='hidden'] {
      transform: scale(0.2) translate(-25%, -50%);
      opacity: 0; }
  .tippy-popper[x-placement^='right'] [data-animation='shift-toward'][data-state='visible'] {
    opacity: 1;
    transform: translateX(10px); }
  .tippy-popper[x-placement^='right'] [data-animation='shift-toward'][data-state='hidden'] {
    opacity: 0;
    transform: translateX(20px); }
  .tippy-popper[x-placement^='right'] [data-animation='perspective'] {
    transform-origin: left; }
    .tippy-popper[x-placement^='right'] [data-animation='perspective'][data-state='visible'] {
      opacity: 1;
      transform: translateX(10px) rotateY(0); }
    .tippy-popper[x-placement^='right'] [data-animation='perspective'][data-state='hidden'] {
      opacity: 0;
      transform: translateX(0) rotateY(60deg); }
  .tippy-popper[x-placement^='right'] [data-animation='fade'][data-state='visible'] {
    opacity: 1;
    transform: translateX(10px); }
  .tippy-popper[x-placement^='right'] [data-animation='fade'][data-state='hidden'] {
    opacity: 0;
    transform: translateX(10px); }
  .tippy-popper[x-placement^='right'] [data-animation='shift-away'][data-state='visible'] {
    opacity: 1;
    transform: translateX(10px); }
  .tippy-popper[x-placement^='right'] [data-animation='shift-away'][data-state='hidden'] {
    opacity: 0;
    transform: translateX(0); }
  .tippy-popper[x-placement^='right'] [data-animation='scale'][data-state='visible'] {
    opacity: 1;
    transform: translateX(10px) scale(1); }
  .tippy-popper[x-placement^='right'] [data-animation='scale'][data-state='hidden'] {
    opacity: 0;
    transform: translateX(0) scale(0.5); }

.tippy-tooltip {
  position: relative;
  color: white;
  border-radius: 4px;
  font-size: 0.9rem;
  padding: 0.3rem 0.6rem;
  max-width: 350px;
  text-align: center;
  will-change: transform;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #333; }
  .tippy-tooltip[data-size='small'] {
    padding: 0.2rem 0.4rem;
    font-size: 0.75rem; }
  .tippy-tooltip[data-size='large'] {
    padding: 0.4rem 0.8rem;
    font-size: 1rem; }
  .tippy-tooltip[data-animatefill] {
    overflow: hidden;
    background-color: transparent; }
  .tippy-tooltip[data-interactive] {
    pointer-events: auto; }
    .tippy-tooltip[data-interactive] path {
      pointer-events: auto; }
  .tippy-tooltip[data-inertia][data-state='visible'] {
    transition-timing-function: cubic-bezier(0.53, 2, 0.36, 0.85); }
  .tippy-tooltip[data-inertia][data-state='hidden'] {
    transition-timing-function: ease; }

.tippy-arrow,
.tippy-roundarrow {
  position: absolute;
  width: 0;
  height: 0; }

.tippy-roundarrow {
  width: 24px;
  height: 8px;
  fill: #333;
  pointer-events: none; }

.tippy-backdrop {
  position: absolute;
  will-change: transform;
  background-color: #333;
  border-radius: 50%;
  width: calc(110% + 2rem);
  left: 50%;
  top: 50%;
  z-index: -1;
  transition: all cubic-bezier(0.46, 0.1, 0.52, 0.98);
  backface-visibility: hidden; }
  .tippy-backdrop::after {
    content: '';
    float: left;
    padding-top: 100%; }

.tippy-backdrop + .tippy-content {
  transition-property: opacity; }
  .tippy-backdrop + .tippy-content[data-state='visible'] {
    opacity: 1; }
  .tippy-backdrop + .tippy-content[data-state='hidden'] {
    opacity: 0; }

@media (max-width: 360px) {
  .tippy-popper {
    max-width: 96%;
    max-width: calc(100% - 20px); } }

/*# sourceMappingURL=tippy.css.map */
